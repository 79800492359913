body{
    background-color: yellow;
}
.home{
    
    .container{ 
        background-color: yellow;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
.navbars{
    padding: 20px;
    h1{
        font-size: 40px;
    }
    .siginbtn{
        margin-left: 10px;
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
        padding:15px 20px;
        border-radius: 20px;
        background-color:blueviolet;
 
    }
   
  max-width: 1000px;
  justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}
.servistitle{
    font-size: 40px;
    padding: 10px;
}
.cards{
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px;
div{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    border: 2px solid;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: cornsilk;
    box-shadow: 10px 15px 10px rgba(31, 30, 30, 0.504);

}
}     
    }
}
